var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "propertyAdd" },
    [
      _c("div", { staticClass: "content bgFFF" }, [
        _c("div", { staticClass: "commonF padding20" }, [
          _c("h2", { staticClass: "title" }, [
            _c("div", { staticClass: "title_icon" }),
            _vm._v(" " + _vm._s(_vm.isEdit ? "编辑" : "添加") + "物品 "),
          ]),
          _c(
            "div",
            {},
            [
              _c(
                "el-form",
                {
                  ref: "form",
                  staticClass: "demo-form-inline",
                  attrs: {
                    inline: false,
                    "label-position": "right",
                    model: _vm.formInline,
                    rules: _vm.rules,
                    "label-width": "100px",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("searchModule.region"),
                        prop: "areaId",
                      },
                    },
                    [
                      _c(
                        "el-button",
                        {
                          staticClass: "dialog_button button_text_left",
                          staticStyle: { width: "200px" },
                          attrs: {
                            title: _vm.areaName ? _vm.areaName : "请选择",
                          },
                          on: { click: _vm.areaIdDialog },
                        },
                        [
                          _vm._v(
                            _vm._s(_vm.areaName ? _vm.areaName : "请选择") + " "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("searchModule.Merchant_Name"),
                        prop: "operationId",
                      },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "200px" },
                          attrs: {
                            filterable: "",
                            size: "15",
                            disabled: _vm.isEdit,
                          },
                          on: {
                            change: function ($event) {
                              return _vm.getList(0)
                            },
                          },
                          model: {
                            value: _vm.formInline.operationId,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.formInline,
                                "operationId",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "formInline.operationId",
                          },
                        },
                        _vm._l(_vm.tenantList, function (value) {
                          return _c("el-option", {
                            key: value.operationId,
                            attrs: {
                              label: value.operationName,
                              value: value.operationId,
                            },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("searchModule.serial_number"),
                        prop: "assetsCode",
                      },
                    },
                    [
                      _c("el-input", {
                        staticStyle: { width: "200px" },
                        attrs: { maxlength: 30 },
                        model: {
                          value: _vm.formInline.assetsCode,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.formInline,
                              "assetsCode",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "formInline.assetsCode",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("searchModule.Item_Name"),
                        prop: "assetsName",
                      },
                    },
                    [
                      _c("el-input", {
                        staticStyle: { width: "200px" },
                        attrs: { maxlength: 20 },
                        model: {
                          value: _vm.formInline.assetsName,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.formInline,
                              "assetsName",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "formInline.assetsName",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("searchModule.Type_of_item"),
                        prop: "assetsTypeParentId",
                      },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "200px" },
                          attrs: { filterable: "", placeholder: "请选择" },
                          on: {
                            change: function ($event) {
                              return _vm.getAssetsTypeList1(
                                _vm.formInline.assetsTypeParentId,
                                true
                              )
                            },
                          },
                          model: {
                            value: _vm.formInline.assetsTypeParentId,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.formInline,
                                "assetsTypeParentId",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "formInline.assetsTypeParentId",
                          },
                        },
                        [
                          _c("el-option", {
                            attrs: { label: "请选择", value: "" },
                          }),
                          _vm._l(_vm.assetsTypeList, function (item) {
                            return _c("el-option", {
                              key: item.assetsTypeId,
                              attrs: {
                                label: item.assetsTypeName,
                                value: item.assetsTypeId,
                              },
                            })
                          }),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("searchModule.Item_model"),
                        prop: "assetsTypeId",
                      },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "200px" },
                          attrs: { filterable: "", placeholder: "请选择" },
                          model: {
                            value: _vm.formInline.assetsTypeId,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.formInline,
                                "assetsTypeId",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "formInline.assetsTypeId",
                          },
                        },
                        [
                          _c("el-option", {
                            attrs: { label: "请选择", value: "" },
                          }),
                          _vm._l(_vm.assetsList, function (item) {
                            return _c("el-option", {
                              key: item.assetsTypeId,
                              attrs: {
                                label: item.assetsTypeName,
                                value: item.assetsTypeId,
                              },
                            })
                          }),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("searchModule.state"),
                        prop: "status",
                      },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "200px" },
                          attrs: { filterable: "", placeholder: "请选择" },
                          on: { change: _vm.statusChange },
                          model: {
                            value: _vm.formInline.status,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.formInline,
                                "status",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "formInline.status",
                          },
                        },
                        [
                          _c("el-option", {
                            attrs: { label: "认领", value: 1 },
                          }),
                          _c("el-option", {
                            attrs: { label: "空闲", value: 0 },
                          }),
                          _vm.isEdit
                            ? _c("el-option", {
                                attrs: { label: "废弃", value: 2 },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("searchModule.Recipient"),
                        prop: "pdaManagerId",
                      },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "200px" },
                          attrs: {
                            filterable: "",
                            size: "15",
                            disabled:
                              _vm.formInline.status === 0 ||
                              _vm.formInline.status === 2,
                          },
                          model: {
                            value: _vm.formInline.pdaManagerId,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.formInline,
                                "pdaManagerId",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "formInline.pdaManagerId",
                          },
                        },
                        _vm._l(_vm.pdaManagerList, function (value) {
                          return _c("el-option", {
                            key: value.pdaManagerId,
                            attrs: {
                              label: value.pdaManagerName,
                              value: value.pdaManagerId,
                            },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-button",
                    {
                      staticStyle: { "margin-left": "100px" },
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.submit()
                        },
                      },
                    },
                    [_vm._v("提交")]
                  ),
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.$router.go(-1)
                        },
                      },
                    },
                    [_vm._v("取消")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]),
      ]),
      _c("tree-con", {
        ref: "treeConpent",
        attrs: { defaultProps: _vm.defaultProps, treeData: _vm.treeData },
        on: { treeSure: _vm.treeSure, treeCandle: _vm.treeCandle },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }